<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">CheckIn List</h5>
        </b-card-body>
        
        <div class="p-3 d-flex">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="9"
            label-size="sm"
            label-class="fw-medium"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <div class="ml-auto mr-2">
              <b-form-group>
              <b-input-group class="d-flex">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    variant="danger"
                    :disabled="!filter"
                    @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </div>
        </div>

        <b-table
          responsive
          class="mb-0"
          head-variant="light"
          v-if="items"
          :fields="fields"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(product_code)="data"><i class="fas fa-barcode"></i> {{ data.item.product_code }} </template>
          <!--<template #cell(user)="data">
            <b-img
              :src="require('@/assets/images/users/' + data.item.user.image)"
              rounded="circle"
              :alt="data.item.user.image"
              width="40"
            />
            <span class="ml-2 fw-medium"
              >{{ data.item.user.first }} {{ data.item.user.last }}</span
            >
          </template>
          <template #cell(team)="data">
            <b-badge pill variant="primary" class="pr-2">
              <b-img
                :src="
                  require('@/assets/images/users/' + data.item.team.teamimg1)
                "
                rounded="circle"
                :alt="data.item.team.teamimg1"
                width="25"
              />
              {{ data.item.team.team1text }}
            </b-badge>
            <b-badge pill variant="info" class="pr-2 ml-2">
              <b-img
                :src="
                  require('@/assets/images/users/' + data.item.team.teamimg2)
                "
                rounded="circle"
                :alt="data.item.team.teamimg2"
                width="25"
              />
              {{ data.item.team.team2text }}
            </b-badge>
            <b-img
              :src="require('@/assets/images/users/' + data.item.team.teamimg1)"
              rounded="circle"
              :alt="data.item.team.teamimg1"
              width="35"
              class="mr-n2 border border-white"
            />
            <b-img
              :src="require('@/assets/images/users/' + data.item.team.teamimg2)"
              rounded="circle"
              :alt="data.item.team.teamimg2"
              width="35"
              class="mr-n2 border border-white card-hover"
            />
            <b-img
              :src="require('@/assets/images/users/' + data.item.team.teamimg3)"
              rounded="circle"
              :alt="data.item.team.teamimg3"
              width="35"
              class="border border-white"
            />
          </template>
          <template #cell(quantity)="data"> 0{{data.item.quantity}} </template>-->
          <template #cell(status)="data">
            <b-badge
              pill
              :class="`px-2 text-white badge bg-${data.item.status===1? 'success':'danger'}`"
            >
              <i class="font-9 mdi mdi-checkbox-blank-circle"></i>
              {{ data.item.status===1? 'In Process':'Confirmed' }}
            </b-badge>
          </template>
          
          <template #cell(actions)="data">
            <a class="mx-1 cursor-pointer" @click="loadCheckin(data.item.checkin_id)"
            ><feather type="eye" class="feather-sm"></feather
            ></a> 
            <!--<a
            class="mx-1 cursor-pointer"
            ><feather type="edit" class="feather-sm" @click="loadCheckin(data.item.checkin_id)"></feather
            ></a>-->
            <a
            class="mx-1 cursor-pointer" @click="deleteCheckin(data.item.checkin_id)"
            ><feather type="trash" class="feather-sm"></feather
            ></a>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "CheckIns",

  data: () => ({
    page: {
      title: "CheckIns",
    },
    filter: null,
    fields: [
      {
        key: "checkin_id",
        label: "Purchase Order",
      },
      {
        key: "purchase_ref",
        label: "Purchase Ref",
      },
      {
        key: "supplier_name",
        label: "Supplier",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "datetime",
        label: "Date",
      },
      {
        key: "actions",
        label: "Actions",
      }
    ],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
  }),
  components: {},
  mounted() {
    this.loadCheckins();
  },
  methods:{
    ...mapActions(["getCheckins","delCheckin","getCheckinProducts","delCheckinProduct"]),
    loadCheckins(){
      this.getCheckins().then((res) => {
        this.items = res.data;
        // Set the initial number of items
        this.totalRows = this.items.length;
      })
    },
    loadCheckin(id){
        this.$router.push({ name: "Check In", params: {'id': id} });
    },
    deleteCheckin(id){
      confirm("Are you sure you want to delete this item?") &&
        this.delCheckin(id).then(() => {
          this.getCheckinProducts(id).then((res) => {
            res.data.forEach(cip => {
              this.delCheckinProduct(cip.checkin_product_id);
            });
          });
          this.loadCheckins();
        });
    }
  }
};
</script>
